@use 'base';

.form-container {
  &-contact {
    padding: 4.5rem 1.6rem 0;
  }

  .done-section-hubspot-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &-group,
    .hs-form-field {
      margin-bottom: 5.2rem;
      position: relative;

      &-label {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        position: absolute;
        left: 15px;
        top: 25px;
        transition: 0.3s ease;
        text-transform: uppercase;
        color: var(--done-color-777);

        &.hs-form-booleancheckbox-display {
          position: relative;
          top: 0;
          left: 0;

          &.active {
            background: none;
          }
        }

        &.active {
          top: -10px;
          font-size: var(--done-font-size-16);
          padding: 0 4px;
          z-index: 2;
          background-color: var(--done-color-333834);
        }
      }

      input:not([type='checkbox']),
      textarea,
      select {
        border: 0.1rem solid var(--done-color-eaeaf6);
        min-height: 7.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-right: 2.2rem;
        background: transparent;
        color: var(--done-color-eaeaf6);
        padding-inline: 1.6rem;
        box-sizing: border-box;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-2);

        &:not(:placeholder-shown) + .done-section-hubspot-form-group-label,
        &:focus + .done-section-hubspot-form-group-label {
          top: -10px;
          font-size: var(--done-font-size-16);
          padding: 0 4px;
          background: var(--done-color-333834);
        }

        &:focus-within {
          outline: 0.1rem solid var(--done-color-eaeaf6);
        }

        &::placeholder {
          color: var(--done-color-333834);
        }
      }

      select {
        background: var(--done-color-333834);
      }

      &.hs-fieldtype-select {
        .done-section-hubspot-form-group-label {
          position: absolute;
          top: -10px;
          font-size: var(--done-font-size-16);
          padding: 0 4px;
          background-color: var(--done-color-333834);
          z-index: 2;
        }
      }

      .hs-error-msg {
        position: absolute;
        background-color: var(--done-color-333834);
        top: 8rem;
        left: 1rem;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        text-transform: uppercase;
      }

      textarea {
        padding-block: 1.6rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    .hs-submit {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 4rem 0;

      .actions {
        width: 50%;
      }
    }
    .legal-consent-container {
      .hs-richtext {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-16) /
          var(--done-line-spacing-25) var(--done-font-family-pp-neue-montreal);
        margin: 2rem 0;

        a {
          text-decoration: underline;
        }
      }
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--done-font-size-14);
      }

      input {
        height: 2rem;
        width: 2rem;
        margin: 0;
        accent-color: var(--done-color-00ff89);
      }
    }

    .hs_error_rollup {
      display: flex;
      justify-content: center;
      background-color: var(--done-color-333834);
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-16) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);
      text-transform: uppercase;
    }

    &-button {
      text-align: center;
    }

    &-toast-container {
      margin-top: 4.8rem;
    }

    &-toast-container:has(.toast-container) {
      margin-top: 0;
    }
  }

  select {
    -webkit-appearance: none;
  }
}
.form-contact-container {
  .done-section-hubspot-form {
    flex-direction: row;
    flex-wrap: wrap;

    .field {
      width: 100%;
      margin-bottom: 4.2rem;

      &.hs-firstname,
      &.hs-lastname {
        width: 48%;
      }

      &.hs-fieldtype-textarea {
        margin-bottom: 0;
      }
    }

    .hs-submit {
      margin-top: 0;
    }
  }
  .done-section-hubspot-form-group-label:not(
      .hs-form-booleancheckbox-display
    ).active,
  .done-section-hubspot-form
    .hs-form-field-label:not(.hs-form-booleancheckbox-display).active {
    background-color: transparent;
    opacity: 1;
  }

  .done-section-hubspot-form-group-label:not(.hs-form-booleancheckbox-display),
  .done-section-hubspot-form
    .hs-form-field-label:not(.hs-form-booleancheckbox-display) {
    opacity: 0.22;
  }

  .done-section-hubspot-form-group select {
    & option {
      color: var(--done-color-000000);
      text-transform: uppercase;
    }
  }

  .done-section-hubspot-form-group input:not([type='checkbox']),
  .done-section-hubspot-form-group textarea,
  .done-section-hubspot-form-group select,
  .done-section-hubspot-form .hs-form-field input:not([type='checkbox']),
  .done-section-hubspot-form .hs-form-field textarea,
  .done-section-hubspot-form .hs-form-field select {
    background: var(--done-color-eaeaf6);
    box-shadow: 0 4px 4px #0000001a;
    color: var(--done-color-000000);
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
      var(--done-font-size-20) / var(--done-line-spacing-20)
      var(--done-font-family-signal-mono);

    &:invalid,
    & option[value=''] {
      color: rgba(0, 0, 0, 0.22);
    }
  }
  .done-section-hubspot-form .hs-form-field textarea {
    min-height: 15rem;
    text-transform: none;
  }

  .done-section-hubspot-form-group.hs-fieldtype-select
    .done-section-hubspot-form-group-label,
  .done-section-hubspot-form
    .hs-form-field.hs-fieldtype-select
    .done-section-hubspot-form-group-label {
    background-color: transparent;
  }

  .done-section-hubspot-form-group .hs-error-msg,
  .done-section-hubspot-form .hs-form-field .hs-error-msg {
    background-color: transparent;
    font-size: var(--done-font-size-16);
  }
}

@media only screen and (max-width: base.$large-devices) {
  .form-contact-container {
    .done-input-group-input,
    .done-input-group .hs-input {
      margin: 0;
    }

    .done-section-hubspot-form .field {
      margin-bottom: 6.2rem;
    }
  }
}
@media only screen and (min-width: base.$large-devices) {
  .form-container {
    &-contact {
      padding: 21rem 11rem 0;
      width: 50%;
    }
    .done-section-hubbspot-form {
      &-group,
      .hs-form-field {
        margin-bottom: 5.2rem;

        input,
        textarea,
        .hs-input {
          &:not(:placeholder-shown) + .done-section-hubspot-form-group-label,
          &:focus + .done-section-hubspot-form-group-label {
            font-size: var(--done-font-size-18);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-toast-container {
        margin-top: 6.4rem;
      }
    }
  }
}
